<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="resourceForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="岗位"
              prop="station"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.station"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="薪资待遇"
              prop="salary"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input placeholder="请输入" v-model="info.salary"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="学历"
              prop="education"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.education"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="毕业时间"
              prop="graduationTime"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input placeholder="请输入" v-model="info.graduationTime"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="到岗时间"
              prop="arrivalTime"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.arrivalTime"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="工作经验"
              prop="workExperience"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model="info.workExperience"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="职位要求"
              prop="postRequire"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model="info.postRequire"
              ></a-input>
            </a-form-model-item>
          </a-col>
          
        </a-row>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <!-- <pxkeji-btn name="保存" type="primary" @click="handleSubmit" /> -->
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  name: "RecruitRequire",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      info: {},
      rules:{},
    };
  },
  mounted() {
    _this = this;
    if (_this.infos.id != 0) {
      _this.getInfo();
    }
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },

    //详情
    getInfo() {
      _this.isloading = true;
      _this.$api.recruit
        .getRecruitRequire(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },

    onChange() {
      this.$forceUpdate();
    },
  },
};
</script>
<style>
</style>